import React, { memo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'
import { Image } from '@elements'
import { useStaticQuery, graphql } from 'gatsby'
import { partners as graphqlPartners } from '@graphql'
import { Partners } from '@types'
import { Header, Hero, PortfolioList, PortfolioReviews, Footer } from '@components'

import styles from './index.module.scss'

interface PortfolioReviewsProps extends RouteComponentProps {}

const Portfolio: React.FC<PortfolioReviewsProps> = props => {
  const [video, setVideo] = useState<boolean>(false)
  const { data: { res: partners } = {}, loading: partnersLoading } = useQuery<{ res: Partners }>(
    graphqlPartners.FetchPartners,
    { variables: { type: 'PORTFOLIO' } }
  )

  const imagesQuery = graphql`
    query {
      portfolio: file(relativePath: { eq: "portfolioPrev.jpg" }) {
        ...ImageFluid1440x900
      }
      about: file(relativePath: { eq: "aboutPrev.png" }) {
        ...ImageFluid1440x900
      }
      home: file(relativePath: { eq: "homePrev.jpg" }) {
        ...ImageFluid1440x900
      }
      team: file(relativePath: { eq: "teamPrev.png" }) {
        ...ImageFluid1440x900
      }
    }
  `
  const staticData = useStaticQuery(imagesQuery)
  const imageFluid = staticData.portfolio.childImageSharp.fluid

  return (
    <section className={styles.portfolio}>
      <Helmet>
        <title>Palo Santo | Portfolio</title>
        <meta property="og:url" content="https://palosanto.vc/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Palo Santo" />
        <meta
          property="og:description"
          content="Leading psychedelic therapeutics investment fund dedicated to increasing the global supply of clinically effective and accessible healthcare solutions"
        />
        <meta property="og:image" content="/assets/images/social.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <div className={styles.prevideoWrapper}>
        <Image data-video={video} className={styles.prevideo} fluid={imageFluid} />
      </div>
      <video
        data-video={video}
        onLoadedData={() => setVideo(true)}
        className={styles.video}
        muted
        playsInline
        autoPlay
        loop
        src="../assets/videos/PORTFOLIO.mp4"
        poster="../assets/images/portfolio-poster.png"
      >
        <source src="../assets/videos/PORTFOLIO.mp4" type='video/ogg; codecs="theora, vorbis"' />
      </video>
      <Header type="light" />
      <Hero type="portfolio" />

      {!partnersLoading && <PortfolioList partners={partners} />}
      <PortfolioReviews />
      <Footer />
    </section>
  )
}

export default memo(Portfolio)
